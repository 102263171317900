<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
      >
        <div class="py-1 max-w-5xl w-full flex-none">
          <div class="p-4">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>

        <div class="md:py-2 lg:py-12 max-w-2xl w-full">
          <h2
            class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
          >
            Verify You identify
          </h2>
          <h3
            class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-400 to-gray-600"
          >
            It will only take about 2 minutes
          </h3>
          <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
            <div class="p-1">
              <div>
                <div class="">
                  <div class="w-full">
                    <div
                      class="flex border-t border-l border-blue-500 shadow-md border-opacity-25 py-1 px-3 bg-blue-800 rounded-lg bg-opacity-20 mb-2 items-center"
                    >
                      <div class="flex-none">
                        <img
                          class="h-12 inline mr-2"
                          src="@/assets/svg/people.svg"
                        />
                      </div>

                      <div class="flex-grow cursor-pointer">
                        <span
                          class="mr-2 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
                        >
                          Identity Document
                        </span>
                        <br />
                        <span class="text-xs text-gray-300">
                          Take a photo of you ID</span
                        >
                      </div>
                    </div>

                    <div
                      class="flex border-t border-l border-blue-500 shadow-md border-opacity-25 py-1 px-3 bg-blue-800 rounded-lg bg-opacity-20 mb-2 items-center"
                    >
                      <div class="flex-none">
                        <img
                          class="h-12 inline mr-2"
                          src="@/assets/svg/people.svg"
                        />
                      </div>

                      <div class="flex-grow cursor-pointer">
                        <span
                          class="mr-2 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
                        >
                          Identity Document
                        </span>
                        <br />
                        <span class="text-xs text-gray-300">
                          Take a picture of your second document</span
                        >
                      </div>
                    </div>

                    <router-link
                      v-bind:to="'/selectkyc/' + address"
                      class="flex mt-10 border-t border-l border-blue-500 shadow-md border-opacity-25 py-1 px-3 bg-gradient-to-r from-green-600 to-green-800 rounded-lg bg-opacity-20 mb-2 items-center text-center"
                    >
                      <div class="flex-grow cursor-pointer">
                        <h3
                          class="p-3 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-gray-200"
                        >
                          Continue
                        </h3>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div></div>
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      address: this.$route.params.address,
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  methods: {
    computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
  },
  created() {},
};
</script>
